import React from 'react';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import { useInput } from '../../hooks/Hooks';

const isNotEmpty = (value) => value.trim() !== '';

const SecretPasswordForm = (props) => {
    const { showSecretPasswordModal, setShowSecretPasswordModal } = props;
    const {
        value: passwordFieldValue,
        isValid: passwordIsValid,
        hasError: passwordHasError,
        valueChangeHandler: passwordChangeHandler,
        inputBlurHandler: passwordBlurHandler,
        reset: resetForm,
    } = useInput(isNotEmpty);

    const submitHandler = event => {
        event.preventDefault();
        if (process.env.REACT_APP_SECRET_PASSWORD === passwordFieldValue)  verifySecretPassword();
        else return;
        resetForm();
    }

    const verifySecretPassword = () => {
        localStorage.setItem('opengrid::knows_password', true);
        setShowSecretPasswordModal(false);
    }
  
  const firstNameClasses = passwordHasError ? 'form-control invalid' : 'form-control';

  return (<>

    {showSecretPasswordModal && 
        <form onSubmit={submitHandler} className="passwordForm">
            <Box xs={12} paddingTop={'20vh'}>
                <div className={firstNameClasses} style={{ textAlign: 'center', alignItems: 'center'}}>
                    <input
                        type='text'
                        id='password'
                        placeholder='Enter the secret password'
                        value={passwordFieldValue}
                        onChange={passwordChangeHandler}
                        onBlur={passwordBlurHandler}
                        style={{ margin: '40px auto' }}
                    />
                </div>
            </Box>
            <Box xs={12}>
            <div className='form-actions'>
                {passwordIsValid && 
                    <Button type="submit">Submit</Button>
                } 
            </div>
            </Box>
        </form>
    }
  </>);
};

export default SecretPasswordForm;
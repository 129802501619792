import React from "react";
import IndexPage from "./index";
import { Switch, Route, Router } from "./../util/router.js";
import { ThemeProvider } from "./../util/theme.js";
import '../index.css';

function App(props) {
  return (
    <ThemeProvider>
      <Router>
        <Switch>
          <Route exact path="/" component={IndexPage} />
        </Switch>
      </Router>
    </ThemeProvider>
  );
}

export default App;

import React from 'react'

const Hero = (props) => {
      
    return (
        <div className="heroWrapper"  id="home">
            {/* {dimensions.width > 960  ? <> */}
            <div className="heroChild">
                    <div className="hero1">
                    <div>
                        <h1 className="heroTitle-Bold">
                            FIRST
                        </h1>
                        <h1 className="heroTitle-Normal">
                            LOOK
                        </h1>
                        </div>
                    </div>
                </div>
                <div className="heroChild">
                    <div className="hero2">
                        <h4 className="hero2-Title">
                            2021 CHEVROLET CAMARO
                        </h4>
                        <p className="hero2-Description">
                        Chevrolet spices up the 2021 Camaro with new looks 
                            and a 1LE package for turbo-four models
                    </p>
                    <p className="hero2-Subtitle">
                        By GRAEME FLETCHER
                        </p>
                    </div>
                </div>
          
            {/* <div className="heroImg" />         */}
             
        </div>
    )
}

export default Hero;

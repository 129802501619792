import React from 'react'
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";

function FeatureText(props) {
    const { header, descriptionArray } = props;

    return (
        <Grid item={true} xs={12} md={6}>
        <Box textAlign="left" padding="8% 14%" lineHeight="2.5">
          <Typography variant="subtitle1" fontWeight="Bold" gutterBottom={true}>
            {header}
          </Typography>
          {descriptionArray.map((paragraph, index) => (
            <Typography
              variant="body1"
              key={index}
              style={{ paddingBottom: 10 }}
            >
              {paragraph}
            </Typography>
          ))}
        </Box>
      </Grid>
    )
}

export default FeatureText

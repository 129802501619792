import React from 'react';
import Script from '@gumgum/react-script-tag';
import { isMobile } from 'react-device-detect';
import buildConfig from '../../buildConfig';

const OpenGridContainer = (props) => {
  const { setBuild, displayOpenGrid } = props;
  const dynamicBuilds = false;
  const filename = 'Build';
  const directory = !dynamicBuilds ? 'Build' : isMobile ? 'Mobile' : 'Desktop';
  const config = buildConfig(directory, filename);

  const onLoad = () => {
    // setTimeout(() =>
      const canvas = document.getElementById('opengrid-canvas');
      window.createUnityInstance(canvas, config)
        .then((buildInstance) => {
          setBuild(buildInstance)
          const gl = canvas.getContext('webgl2') || canvas.getContext('webgl');
          const ext = gl.getExtension('WEBGL_compressed_texture_astc');
          console.log(`ASTC extension: ${ext}`);
        })
        .catch((message) => console.log(message, '<<<<<<<< ERROR <<<<<<<<<'))
    // , 1000);
  }
  
  return (<>
    <div className={`containerStyle ${displayOpenGrid && 'opengrid'}`}>
      <Script type="text/javascript" src={config.loaderUrl} onLoad={onLoad} />
      <canvas id="opengrid-canvas" />
    </div>
  </>);
};

export default OpenGridContainer;
const buildConfig = (directory, filename) => {
    return {
        dataUrl: `./Build/${directory}/${filename}.data`,
        frameworkUrl: `./Build/${directory}/${filename}.framework.js`,
        codeUrl: `./Build/${directory}/${filename}.wasm`,
        loaderUrl: `./Build//${directory}/${filename}.loader.js`,
        streamingAssetsUrl: `./Build/StreamingAssets`,
        companyName: "Open Grid",
        productName: "opengrid_article",
        productVersion: "0.1",
    }
};
  
export default buildConfig;
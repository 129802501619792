const FeaturesList = [
    {
      image: 'https://res.cloudinary.com/dq5lw8oy1/image/upload/v1630004448/OpenGridLandingPage/Camaro_Mobile_Appearance_hxf01c.png',
      fullImage: 'https://res.cloudinary.com/dq5lw8oy1/image/upload/v1631109653/OpenGridLandingPage/Camaro_Appearance_OpenGrid_spxpdv.png',
      category: 'Retail',
      header: 'As with the Spark, Cruze and Malibu, Chevrolet has given the Camaro bolder front- and rear-end styling, and put a marked differences between the various models for 2021.',
      description: ['The RS appearance package, for example, adds a polished black grille with chrome inserts, new headlamps with an LED signature light bar and a rear fascia with a built-in diffuser. Conversely, the blacked-out central bar running through the grille differentiates the SS model; it is the better take on the new face. Other upgrades include Chevrolet’s new Infotainment 3 system with available GPS navigation, plus an available forward collision alert system and a 10-speed automatic transmission for SS models. The changes are subtle, but noticeable.'],
      reverse: true,
      anchor: 'exterior',
      hoverText: 'TELEPORT INTO THE PHOTO TO SEE THE NEW RS APPEARANCE PACKAGE',
      onClick: 'MapRed',
    },
    {
      image: 'https://res.cloudinary.com/dq5lw8oy1/image/upload/v1630004447/OpenGridLandingPage/RearImage_Upgrades_Mobile_cmlubt.png',
      fullImage: 'https://res.cloudinary.com/dq5lw8oy1/image/upload/v1631109653/OpenGridLandingPage/Camaro_Performance_ai6nzj.png',
      category: '',
      header: '',
      description: ['Since introducing the sixth-generation Camaro in 2016, Chevy has focused on ramping up the performance side of the portfolio. In 2017, the first V6-powered Camaro with the track-oriented 1LE package landed along with the formidable ZL1. For the 2018 model year, the 1LE treatment was added to the ZL1. And now, 2019 sees the first Camaro 1LE powered by the 2.0L turbo-four.',
        'The upgrades include an upgraded suspension with larger anti- roll bars, P245 / 40R20 front and P275 / 35R20 rear tires, Brembo brakes with four - piston front calipers, a limited - slip rear differential, larger coolers for the engine, transmission and differential, along with a competitive driving mode, launch control and prouder exhaust note.It also features “no - lift” shifting – in simple terms, it allows the gas pedal to remain matted between shifts.  It is less than intuitive, but worked well on the track.',
        'Perhaps the most significant upgrade is the fact the lot is now covered under Chevy’s factory warranty even when used at the track.Power for the 2.0L turbo - four remains at 275 horsepower and 295 lb.- ft.of torque'],
      reverse: false,
      anchor: 'performance',
      hoverText: 'TELEPORT INTO THE PHOTO TO SEE THE UPGRADES',
      onClick: 'MapOrange',
    },
    {
      image: 'https://res.cloudinary.com/dq5lw8oy1/image/upload/v1630004448/OpenGridLandingPage/Tires_Mobile_zsupot.png',
      fullImage: 'https://res.cloudinary.com/dq5lw8oy1/image/upload/v1631109653/OpenGridLandingPage/Camaro_Tires_dmmseb.png',
      category: '',
      header: '',
      description: ["Now lest you think the addition of the 1LE package is just for show, well, Chevrolet disagrees. The list of competition the company says it will “battle and beat” on autocross circuits, racetracks and roads across North America reads like a what’s what of affordable speedsters. The intended competition includes the Hyundai Veloster N, Ford Focus ST and RS, Honda Civic Si and Type R and the Kia Stinger, plus Subaru’s WRX, WRX STI and BRZ tS.",
        "Now, before the snickering begins, a couple of hot laps at Las Vegas Motor Speedway’s handling track in a camouflaged model teamed with the exclusive six-speed manual transmission proved the suspension upgrades to do a very good job of keeping the body hunkered down through a fast corner."
        , "Likewise, the beefier tires added more lateral stability — in track mode and with the traction control off, the tester did want to wag its tail, but in a controlled fashion. The upgraded brakes also displayed less fade during the hot laps."],
      reverse: true,
      anchor: 'tires',
      hoverText: 'TELEPORT INTO THE PHOTO TO SEE BEEFIER TIRES',
      onClick: 'MapBlue',
    },
    {
      image: 'https://res.cloudinary.com/dq5lw8oy1/image/upload/v1630004448/OpenGridLandingPage/Interior_End_Mobile_z0328z.png',
      fullImage: 'https://res.cloudinary.com/dq5lw8oy1/image/upload/v1631103986/OpenGridLandingPage/Camaro_Interior_OpenGrid_c1kznu.png',
      category: '',
      header: '',
      description: ['Is the turbocharged Camaro 1LE going to show the likes of a Honda Civic Type R a clean set of rear tires on a hot lap? No, but it is a marked improvement over the base car. The 1LE did not feel as heavy as the roughly 1,550-kilogram curb weight might suggest, meaning it feel less unwieldy as the limit neared. In short, it did a fine job of inspiring confidence.'],
      reverse: false,
      anchor: 'interior',
      hoverText: 'TELEPORT INTO THE PHOTO TO SEE THE INTERIOR',
      onClick: 'BlueInterior',
    },
];
  
export default FeaturesList;
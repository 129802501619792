import React from "react";
import Grid from "@material-ui/core/Grid";
import FeatureText from "./FeatureText";

function Feature(props) {
    const { classes, item, handleClick } = props;
    const [hover, setHover] = React.useState(false);
    const handleHover = () => setHover(true);
    const handleMouseOut = () => setHover(false);

  return (
    <Grid
      className={classes.row}
      container={true}
      item={true}
      alignItems="center"
      spacing={6}
    >
      <Grid item={true} xs={12} md={6}>
        <figure
          className={classes.figure}
          style={
            window.innerWidth < 960
              ? {
                  margin: item.reverse ? "30px 0 0 10%" : "30px 10% 0 0",
                  maxWidth: "100%",
                }
              : { margin: "30px auto", maxWidth: "50vw" }
          }
        >
          <div
            className="beam-image-split"
            onClick={() => handleClick(item.onClick)}
            onTouchStart={handleHover}
            onTouchEnd={handleMouseOut}
            onMouseEnter={handleHover}
            onMouseLeave={handleMouseOut}
          >
            <p className="hoverText" style={{ display: !hover && "none" }}>
              {item.hoverText}
            </p>
            <img src={item.fullImage} alt={item.title}
                className={classes.image}
              style={{
                  margin: window.innerWidth < 960 ? "30px 10% 0 0" : "30px auto",
                  filter: hover ? 'brightness(70%)' : 'brightness(100%)'
              }}
            />
          </div>
        </figure>
      </Grid>
    <FeatureText header={item.header} descriptionArray={item.description} />
    </Grid>
  );
}

export default Feature;

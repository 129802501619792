import React from "react";
import Hero from "../Hero/Hero";
import NavBar from "../NavBar/NavBar";
import Features from "../Features/Features";
import Footer from "../Footer/Footer";
import SecretPasswordForm from "../SecretPasswordForm/SecretPasswordForm";

function LandingPage(props) {
  const { displayOpenGrid, handleOpenGridEntrance, showSecretPasswordModal, setShowSecretPasswordModal} = props;

  return (
    <div
      className="App"
      style={
        displayOpenGrid
          ? {
              opacity: 0,
              transition: "1s",
              transform: "scale(2)",
              filter: "blur(10px)",
              zIndex: -10,
            }
          : {}
      }
    >
        <SecretPasswordForm
          showSecretPasswordModal={showSecretPasswordModal}
          setShowSecretPasswordModal={setShowSecretPasswordModal}
          style={{ visibility: showSecretPasswordModal ? 'visible' : 'hidden'}}
        />
        
        <div style={{ visibility: showSecretPasswordModal ? 'hidden' : 'visible' }}>
          <NavBar />
          <div className="container">
            <Hero />
            <Features handleClick={handleOpenGridEntrance} />
            <Footer />
          </div>
        </div>
        
      
    </div>
  );
}

export default LandingPage;

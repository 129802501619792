import React from 'react'

const NavBar = () => {
    return ( 
        <nav className="navbar navWrapper">
            <a href="/"> 
                <div className="navLogo" />
            </a>
        </nav>
    )
}

export default NavBar;

import React from "react";
import Section from "../Section/Section";
import Container from "@material-ui/core/Container";
import FeaturesList from "./FeaturesList";
import Feature from "./Feature";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  row: {
    "&:nth-of-type(odd)": { flexDirection: "row-reverse" },
    "&:not(:last-child)": { marginBottom: `${theme.spacing(3)}px` },
  },
  body1: {
    fontFamily: "Raleway, sans-serif",
  },
  figure: {
    maxWidth: "50vw",
    margin: "30px auto",
  },
  image: {
    height: "auto",
    maxWidth: "100%",
  },
}));

const Features = (props) => {
  const classes = useStyles();

  return (
    <Section>
      <Container maxWidth={false} disableGutters={true} padding={0}>
        {FeaturesList.map((item, index) => {
          return (
            <Feature
              handleClick={props.handleClick}
              item={item}
              key={index}
              classes={classes}
            />
          );
        })}
      </Container>
    </Section>
  );
};

export default Features;
